import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Button, CardContent, Icon } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Divider from "@material-ui/core/Divider";

import "./Header.css";

function Header() {
  const [anchorEl0, setAnchorEl0] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClose = () => {
    setAnchorEl0(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
  };
  const handleMenu0 = (event) => {
    setAnchorEl0(event.currentTarget);
  };
  const handleMenu1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleMenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleMenu3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [show, handleShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 80) {
        handleShow(true);
      } else handleShow(false);
    });
  }, []);
  return (
    <div className="header">
      <div className="header__logo">
        <Link to="/">
          <img
            src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7cdae7cfa9343a0ba10293_logo-forethought-dark.svg"
            alt=""
          />
        </Link>
      </div>
      {isMobile ? (
        <>
          {" "}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleMenu0}
            aria-label="menu"
            className="burger"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl0}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl0)}
            onClose={() => setAnchorEl0(null)}
          >
            <MenuItem>
              <Link
                to="/platform"
                className="header__link"
                style={{
                  fontWeight: "bolder",
                  color: "darkcyan",
                }}
              >
                Platform
              </Link>
            </MenuItem>

            <Divider />
            <MenuItem>
              <Link
                to="/diet"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Diet
              </Link>
            </MenuItem>

            <MenuItem>
              <Link
                to="/workouts"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Workouts
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/glucose-management"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Glucose-management
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/insights"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Insights
              </Link>
            </MenuItem>
            <MenuItem></MenuItem>
            <MenuItem>
              <Link
                to="/ressources"
                className="header__link"
                style={{
                  fontWeight: "bolder",
                  color: "darkcyan",
                }}
              >
                Ressources
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <Link
                to="/success"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                success-stories
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/webinar"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                webinar
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/blog"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Blog
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/security"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Security
              </Link>
            </MenuItem>
            <MenuItem></MenuItem>
            <MenuItem>
              <Link
                to="/supports"
                className="header__link"
                style={{
                  fontWeight: "bolder",
                  color: "darkcyan",
                }}
              >
                Supports
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <Link
                to="/about"
                className="header__link "
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                About-us
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/faq"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                FAQs
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/careers"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Careers
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/press"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Press
              </Link>
            </MenuItem>
          </Menu>{" "}
        </>
      ) : (
        <div className="header__links">
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl1)}
            onClose={handleClose}
            getContentAnchorEl={null}
          >
            <MenuItem>
              <Link to="/diet" className="header__link">
                Diet
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/workouts" className="header__link">
                Workouts
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/glucose-management" className="header__link">
                glucose-management
              </Link>
            </MenuItem>

            <MenuItem>
              <Link to="/insights" className="header__link">
                Insights
              </Link>
            </MenuItem>
          </Menu>{" "}
          <Button
            className={`header__button ${show && "header__buttonAppear"}`}
            variant="contained"
            onMouseOverCapture={handleMenu1}
          >
            Platform
          </Button>
          <div>
            <Button
              className={`header__button header__link ${
                show && "header__buttonAppear"
              }`}
              variant="contained"
              onClick={handleMenu2}
            >
              Ressources
            </Button>
          </div>
          <Menu
            id="menu-appbar"
            elevation={0}
            anchorEl={anchorEl2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl2)}
            onClose={handleClose}
            getContentAnchorEl={null}
          >
            <MenuItem>
              <Link to="/success" className="header__link">
                success-stories
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/webinar" className="header__link">
                Webinar
              </Link>
            </MenuItem>

            <MenuItem>
              <Link to="/blog" className="header__link">
                Blog
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/security" className="header__link">
                Security
              </Link>
            </MenuItem>
          </Menu>{" "}
          <Button
            className={`header__button "header__link" ${
              show && "header__buttonAppear"
            }`}
            variant="contained"
            onClick={handleMenu3}
          >
            Supports
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl3)}
            onClose={handleClose}
            getContentAnchorEl={null}
          >
            <MenuItem>
              <Link to="/about" className="header__link">
                About Us
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/faq" className="header__link">
                FAQS
              </Link>
            </MenuItem>

            <MenuItem>
              <Link to="/careers" className="header__link">
                Careers
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/press" className="header__link">
                Press
              </Link>
            </MenuItem>
          </Menu>{" "}
          <Link to="/beta" className="header__link">
            <Button endIcon={<ArrowForwardIcon />} variant="contained">
              <Typography style={{ fontSize: 12 }}>join the beta</Typography>
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Header;
