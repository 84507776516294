import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import "./Webinar.css";
import webinarData from "./Data/Webinar.json";

function Webinar() {
  return (
    <div className="webinar">
      <div className="webinar__cover">
        {webinarData.map((webinarContent) => {
          return (
            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                md={6}
                style={{ backgroundColor: "#0cbaa9" }}
                align="center"
              >
                <Typography
                  variant="h3"
                  align="center"
                  style={{ color: "white", fontWeight: "bolder" }}
                >
                  {webinarContent.title}
                </Typography>
                <img
                  src="https://assets.website-files.com/5e78c024a853e148aafdb943/5e8608ec95d20b145a05818a_1_CztOQNnWHXKcVZfpBYB4rw%202.png"
                  alt=""
                  style={{ width: 400, height: 400, objectFit: "contain" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" align="center">
                  {webinarContent.date}
                  <Typography align="center" variant="h2">
                    {webinarContent.title}
                    <Typography variant="h6" align="left">
                      {webinarContent.description}
                    </Typography>
                  </Typography>
                  <Typography variant="button" align="left">
                    <Button
                      variant="contained"
                      endIcon={<ForwardArrowIcon />}
                      style={{
                        backgroundColor: "darkcyan",
                        color: "white",
                        fontWeight: "bolder",
                      }}
                    >
                      {" "}
                      Reserve Your Spot
                    </Button>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

export default Webinar;
