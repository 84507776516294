import React from "react";
import "./Description.css";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
function Description() {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));

  const classes = useStyles();

  return (
    <div className="description">
      <Typography align="center">
        <h1>How Agatha Works </h1>
        <body1>
          Agatha is an AI-powered customer support agent who uses machine
          learning and natural language understanding to immediately respond to
          customers to resolve simpler issues, automate repetitive routing that
          agents spend valuable time doing, and provide real-time assistance and
          insights to agents and managers as they work to resolve issues more
          efficiently.
        </body1>
      </Typography>
      <div className="description__table">
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <h1>Aghata</h1>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Solve</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Triage</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper}>Assist</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper}>Search</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper}>Analytics</Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper}>Insight</Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Description;
