import React from "react";
import "./Logos.css";
import Grid from "@material-ui/core/Grid";
import logoData from "./Data/Logo.json";

function Logos() {
  return (
    <div className="logos">
      <Grid container justify="space-between" alignContent="center">
        {logoData.map((logoContent) => {
          return (
            <Grid container xs={12} sm={4} md={2} justify="center">
              <img src={logoContent.imageUrl} alt="" />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Logos;
