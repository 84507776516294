import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";

import "./Careers.css";

function Careers() {
  return (
    <div className="careers">
      <div className="careers__cover">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" align="center">
              Help build better knowledge tools for enterprise
              <Typography variant="subtitle1" align="center">
                We are expanding our product, sales, design, and engineering
                teams.
              </Typography>
            </Typography>
            <div className="careers__coverButton">
              <Button variant="contained" endIcon={<ForwardArrowIcon />}>
                See Open Position
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e86232662387a13c23a6b5d_header-img-careers-p-800.png"
              alt=""
            />
          </Grid>
        </Grid>
      </div>
      <div className="careers__description">
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Typography align="left" display="block" variant="h4">
              We are building the future of unstructured information in the
              enterprise, tackling a $30B+ market opportunity, and we’re
              assembling a world-class team to help us get there.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography align="left" display="block" variant="subtitle1">
              Founded by a team of Facebook, Dropbox, and LinkedIn alumni in
              2017, Forethought’s mission is to "enable everyone to be a genius
              at their job". We're building AI-driven user experiences that
              embed information into employee workflows, starting with our AI
              solution for customer support agents: Agatha. Forethought launched
              and won at TechCrunch Disrupt -- the world's most prestigious
              startup competition -- and was featured in Forbes 30 Under 30.
            </Typography>
          </Grid>
          <Grid item xs={12} align="left">
            <Typography
              style={{ paddingLeft: 20 }}
              display="block"
              variant="h4"
            >
              Be a Forethinker
            </Typography>
            <Grid item xs={7} align="left">
              <Typography
                style={{ paddingLeft: 20 }}
                display="block"
                variant="subtitle1"
              >
                We invest in the personal and professional growth of every
                member of our team because we believe growth leads to both
                business impact and personal fulfillment
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className="careers__grid">
          <Grid container direction="row" spacing={5}>
            <Grid item xs={3} md={1}>
              <img
                src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e8624e7fe92134575231dd2_icon-value-1.svg"
                alt=""
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <Typography
                display="block"
                variant="h5"
                align="left"
                style={{ paddingLeft: 25 }}
              >
                Put People First
                <Typography display="block" variant="subtitle1" align="left">
                  We focus on the needs and experience of the user as much as
                  the tech. Customer success as much as sales. We aim to
                  delight.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} md={1}>
              <img
                src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e8624e7fe92134575231dd2_icon-value-1.svg"
                alt=""
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <Typography
                display="block"
                variant="h5"
                align="left"
                style={{ paddingLeft: 25 }}
              >
                Put People First
                <Typography display="block" variant="subtitle1" align="left">
                  We focus on the needs and experience of the user as much as
                  the tech. Customer success as much as sales. We aim to
                  delight.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} md={1}>
              <img
                src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e8624e7fe92134575231dd2_icon-value-1.svg"
                alt=""
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <Typography
                display="block"
                variant="h5"
                align="left"
                style={{ paddingLeft: 25 }}
              >
                Put People First
                <Typography display="block" variant="subtitle1" align="left">
                  We focus on the needs and experience of the user as much as
                  the tech. Customer success as much as sales. We aim to
                  delight.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} md={1}>
              <img
                src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e8624e7fe92134575231dd2_icon-value-1.svg"
                alt=""
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <Typography
                display="block"
                variant="h5"
                align="left"
                style={{ paddingLeft: 25 }}
              >
                Put People First
                <Typography display="block" variant="subtitle1" align="left">
                  We focus on the needs and experience of the user as much as
                  the tech. Customer success as much as sales. We aim to
                  delight.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} md={1}>
              <img
                src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e8624e7fe92134575231dd2_icon-value-1.svg"
                alt=""
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <Typography
                display="block"
                variant="h5"
                align="left"
                style={{ paddingLeft: 25 }}
              >
                Put People First
                <Typography display="block" variant="subtitle1" align="left">
                  We focus on the needs and experience of the user as much as
                  the tech. Customer success as much as sales. We aim to
                  delight.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} md={1}>
              <img
                src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e8624e7fe92134575231dd2_icon-value-1.svg"
                alt=""
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <Typography
                display="block"
                variant="h5"
                align="left"
                style={{ paddingLeft: 25 }}
              >
                Put People First
                <Typography display="block" variant="subtitle1" align="left">
                  We focus on the needs and experience of the user as much as
                  the tech. Customer success as much as sales. We aim to
                  delight.
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Careers;
