import React from "react";
import "./Security.css";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";

function Security() {
  return (
    <div className="security">
      <div className="security__cover">
        <div className="security__coverText">
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Typography variant="h3" display="block">
                Secure and private by default
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" display="block">
                We take the responsibility of helping you manage your customer
                data seriously. That’s why security and privacy are key focus
                areas for our organization and product development.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="security__description">
        <Typography variant="h4" align="left" display="inline">
          Forethought’s commitment to data privacy
          <Typography variant="subtitle1" align="left" display="block">
            Adhering to local regulations is only one component of our
            commitment to privacy. Our higher order mission is to treat you and
            your customers with the respect you deserve.
          </Typography>
        </Typography>
      </div>
      <div className="security__section1">
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={6} align="center">
            <Typography variant="h4">
              1<Typography variant="h6">Data Processing Agreement</Typography>
              <Typography variant="subtitle1">
                Our Data Processing Agreement (DPA) reflects the requirements of
                the GDPR, and CCPA
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} align="center">
            <Typography variant="h4">
              2<Typography variant="h6">Data Processing Agreement</Typography>
              <Typography variant="subtitle1">
                Our Data Processing Agreement (DPA) reflects the requirements of
                the GDPR, and CCPA
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} align="center">
            <Typography variant="h4">
              3<Typography variant="h6">Data Processing Agreement</Typography>
              <Typography variant="subtitle1">
                Our Data Processing Agreement (DPA) reflects the requirements of
                the GDPR, and CCPA
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} align="center">
            <Typography variant="h4" align="center">
              4<Typography variant="h6">Data Processing Agreement</Typography>
              <Typography variant="subtitle1">
                Our Data Processing Agreement (DPA) reflects the requirements of
                the GDPR, and CCPA
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="security__section2">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {" "}
              Internal Security
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography align="left" variant="h6">
              Data Encryption
              <Typography align="left" vairant="subtitle1">
                Your data is encrypted at rest and protected by TLS in transit.
                We manage our production secrets with AWS tools.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography align="left" variant="h6">
              Data Encryption
              <Typography align="left" vairant="subtitle1">
                Your data is encrypted at rest and protected by TLS in transit.
                We manage our production secrets with AWS tools.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography align="left" variant="h6">
              Data Encryption
              <Typography align="left" vairant="subtitle1">
                Your data is encrypted at rest and protected by TLS in transit.
                We manage our production secrets with AWS tools.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="security__section3">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {" "}
              Compliance standard
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              {" "}
              Forethought operates in compliance with key information security
              standards and regulations. Our services are independently audited
              and certified to meet compliance standards for security,
              availability and confidentiality. We are compliant with ISO 27001
              and certified for SOC 2.
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e78c024a853e1344dfdbaf7_aicpa_sos-p-500.png"
              alt=""
            />
          </Grid>
        </Grid>
      </div>
      <div className="security__link">
        <Typography align="left" variant="h4">
          General Security Questions
          <Typography align="left" variant="subtitle1">
            If you have general security questions or concerns please email us
            at security @ forethought.ai.
          </Typography>
          <Button
            endIcon={<ForwardArrowIcon />}
            variant="contained"
            style={{ height: 40, borderRadius: 10 }}
          >
            <Typography variant="button">Email Us</Typography>
          </Button>
        </Typography>
      </div>
    </div>
  );
}

export default Security;
