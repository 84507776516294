import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./FAQs.css";
import faqData from "./Data/Faq.json";

function FAQs() {
  return (
    <div className="faqs">
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Typography display="block" variant="h3" align="center">
            Frequently Asked Questions
            <Typography display="block" variant="body1" align="center">
              Apples to apples comparison of enterprise technology is difficult,
              and it is no exception for AI solutions in customer service.
              <Typography>
                {" "}
                We have compiled the most common questions we find enterprises
                asking themselves about Agatha and the power of Forethought so
                you can make the best decision for your organization.
              </Typography>
              ‍ We have compiled the most common questions we find enterprises
              asking themselves about Agatha and the power of Forethought so you
              can make the best decision for your organization.
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <img
            src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7e5541d627fa4c5df72816_graphic-header-FAQs.svg"
            alt=""
          />
        </Grid>
      </Grid>
      <div className="faqs__accordion">
        {faqData.map((faqContent) => {
          return (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{faqContent.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faqContent.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default FAQs;
