import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import "./About.css";
import aboutData from "./Data/About.json";

function About() {
  return (
    <div className="about">
      <div className="about__description">
        <Typography display="initial" variant="h3" align="center">
          Our mission is to use AI to make everyone a genius at their job.
          <Typography
            display="block"
            variant="subtitle1"
            align="center"
            className="about__descriptionSubtitle"
          >
            Forethought is an AI company that creates order, removes redundant
            work, and provides efficiency for businesses everywhere. We’re
            changing the way these enterprises access, share, and leverage their
            knowledge.
          </Typography>
        </Typography>
      </div>
      <div className="about__team">
        <Typography variant="h3" align="center">
          Our Team
          <Typography variant="subtitle1" align="center">
            Meet the team that set the wheels in motion
          </Typography>
        </Typography>
        <div className="about__teamFounder">
          <Grid container direction="row" spacing={10}>
            {aboutData.map((userDetails) => {
              return (
                <Grid item xs={4}>
                  <Avatar
                    className="about__avatar"
                    src={userDetails.Founder.imageUrl}
                  />
                  <Typography align="center" variant="h6">
                    {userDetails.Founder.name}
                    <Typography align="center" variant="body1">
                      {userDetails.Founder.description}
                    </Typography>
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>

        <div className="about__teamMembers">
          <Grid container spacing={5}>
            {aboutData.map((userDetails) => {
              return (
                <Grid item xs={4} sm={3}>
                  <Avatar
                    className="about__avatar"
                    src={userDetails.Members.imageUrl}
                  />
                  <Typography align="center" variant="h6">
                    {userDetails.Members.name}
                    <Typography align="center" variant="body1">
                      {userDetails.Members.description}
                    </Typography>
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div className="about__teamBoard">
          <Typography variant="h3" align="center">
            Meet Our Board
          </Typography>
          <Grid container spacing={5} className="about_teamBoardGrid">
            {aboutData.map((userDetails) => {
              return (
                <Grid item xs={4}>
                  <Avatar
                    className="about__avatar"
                    src={userDetails.Board.imageUrl}
                  />
                  <Typography align="center" variant="h6">
                    {userDetails.Board.name}
                    <Typography align="center" variant="body1">
                      {userDetails.Board.description}
                    </Typography>
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default About;
