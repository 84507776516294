import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import "./Press.css";
import pressData from "./Data/Press.json";

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  media: {
    height: 400,
    width: 300,
  },
});
function Press() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="press">
      <div className="press__cover">
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" align="left">
              Forethought Earns SOC 2 Type II Certification
              <Typography variant="subtitle1">
                At Forethought, we are committed to keeping our customers’ data
                safe and secure. This April, we took another big step in
                demonstrating this commitment, by successfully completing our
                SOC 2 Type II certification.
              </Typography>
              <Typography variant="button" align="left">
                <Button
                  variant="contained"
                  endIcon={<ForwardArrowIcon />}
                  style={{
                    backgroundColor: "darkcyan",
                    color: "white",
                    fontWeight: "bolder",
                  }}
                >
                  {" "}
                  Read more
                </Button>
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src="https://assets.website-files.com/5e78c024a853e148aafdb943/5f18d518d09224ddd3295122_blog-post-header-soc-2.png"
              alt=""
            />
          </Grid>
        </Grid>
      </div>
      <div className="press__cards">
        <Grid container spacing={10}>
          {pressData.map((pressContent) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={pressContent.imageUrl}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {pressContent.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {pressContent.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      style={{ color: "darkcyan" }}
                      endIcon={<ForwardArrowIcon />}
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}

export default Press;
