import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import "./Workouts.css";

function Workouts() {
  return (
    <div className="workouts">
      <div className="workouts__cover">
        {" "}
        <div className="workouts__coverDescription">
          <Grid container>
            <Grid item xs={6} sm={6}>
              <Typography variant="h4" align="left">
                Route customer issues correctly.
                <Typography variant="h6" display="">
                  Agatha automates the triage process, categorizing issues for
                  you as they come into the helpdesk.
                </Typography>
                <Typography variant="button">
                  <Button
                    size="large"
                    variant="contained"
                    endIcon={<ForwardArrowIcon />}
                    style={{
                      color: "#ff6058",
                    }}
                  >
                    Lets chat
                  </Button>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="workouts__section1">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e97476469d2126573340946_graphic-predictions-3%20(1)-p-500.png"
              alt=""
              style={{ height: 500 }}
            />
          </Grid>
          <Grid item xs={12} md={6} align="left">
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e78cdece1ac65186d3a6bf0_icon-predictions%20(1).svg"
              alt=""
            />
            <Typography align="left" variant="h5" style={{ color: "red" }}>
              Workouts
            </Typography>
            <Typography variant="h3" align="left" display="block">
              Automatically route issues to the right place for resolution
              <Typography variant="h6" align="left" display="block">
                Agatha categorizes tickets instantly with a high degree of
                accuracy that makes it easy to organize your incoming tickets
                and cases.
              </Typography>
              <Typography variant="h6" align="left">
                Ready to see Aghata in action?
              </Typography>
              <Typography variant="button">
                <Button
                  size="large"
                  variant="contained"
                  endIcon={<ForwardArrowIcon />}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  Lets chat
                </Button>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="workouts__section2">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" align="left" display="block">
              Manage your support queue without the hassle
              <Typography variant="h6" align="left" display="block">
                Agatha Predictions uses Natural Language Understanding (NLU) to
                route tickets to the right team. Say goodbye to the days where
                issues sit in an inbox without being properly escalated or
                routed.
              </Typography>
              <Typography variant="h6" align="left">
                Want to see what Agatha can do for you?
              </Typography>
              <Typography variant="button">
                <Button
                  size="large"
                  variant="contained"
                  endIcon={<ForwardArrowIcon />}
                  style={{
                    backgroundColor: "#ff6058",
                    color: "white",
                  }}
                >
                  Lets chat
                </Button>
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7d2d6bf38d69668610f448_graphic-predictions-4.svg"
              alt=""
            />
          </Grid>
        </Grid>
      </div>

      <div className="workouts__section3">
        <Typography variant="h3" align="center">
          Why Workouts?
        </Typography>
        <Grid container spacing={10}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="workouts__section4">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{ color: "white", paddingLeft: 50 }}
              variant="h4"
              align="left"
            >
              Scale Your Customer Support Operations
              <Typography variant="h6" xs={5} align="left">
                Help your customer support agents focus on delighting customers
              </Typography>
              <Typography variant="button">
                <Button
                  size="medium"
                  variant="contained"
                  endIcon={<ForwardArrowIcon />}
                  style={{
                    color: "#ff6058",
                    backgroundColor: "white",
                    marginBottom: 20,
                  }}
                >
                  Lets chat
                </Button>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Workouts;
