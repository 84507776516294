import React from "react";
import "./ContactUs.css";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Divider from "@material-ui/core/Divider";

function ContactUs() {
  return (
    <div className="contactUs">
      <div className="contactUs__bg">
        <div className="contactUs__nav">
          <Typography
            variant="h3"
            align="center"
            className="contactUs__navHeader"
          >
            Contact Us
          </Typography>
          <div className="contactUs__cards">
            <Grid container spacing={10}>
              <Grid item xs={12} lg={4} container style={{ width: 400 }}>
                <Card className="card">
                  <CardContent>
                    <Typography variant="h5" component="h2" align="center">
                      Sales
                    </Typography>

                    <Typography
                      variant="body2"
                      component="p"
                      align="center"
                      display="inline"
                      className="text"
                    >
                      We’d love to talk about how we can work together.
                    </Typography>
                  </CardContent>
                  <CardActions className="contactUs__navButton">
                    <Button size="small" endIcon={<ArrowForwardIcon />}>
                      Contact Sales
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} lg={4} container>
                <Card className="card">
                  <CardContent>
                    <Typography variant="h5" component="h2" align="center">
                      Help &amp; Support
                    </Typography>

                    <Typography
                      variant="body2"
                      component="p"
                      align="center"
                      display="inline"
                    >
                      We’re here to help with any questions or issues.
                    </Typography>
                  </CardContent>
                  <CardActions className="contactUs__navButton">
                    <Button size="small" endIcon={<ArrowForwardIcon />}>
                      Visit Helpcenter
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} lg={4} container>
                <Card className="card">
                  <CardContent>
                    <Typography variant="h5" component="h2" align="center">
                      Media &amp; Press
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      align="center"
                      display="inline"
                    >
                      Get Forethought news, company info.
                    </Typography>
                  </CardContent>
                  <CardActions className="contactUs__navButton">
                    <Button size="small" endIcon={<ArrowForwardIcon />}>
                      Browse Media
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="contactUs__infos">
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6}>
            <Typography align="center" variant="h5">
              General Communications
              <Typography align="center" variant="body1">
                For general queries, including partnership opportunities, please
                email info@forethought.ai
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography align="center" variant="h5">
              Support issues
              <Typography align="center" variant="body1">
                For support queries, including technical related topics, please
                email support@forethought.ai
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ContactUs;
