import React from "react";
import "./Footer.css";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";

function Footer() {
  return (
    <div className="footer">
      <img
        className="footer__logo"
        src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7cdae7cfa9343a0ba10293_logo-forethought-dark.svg"
        alt=""
      ></img>
      <div className="footer__grid">
        <Grid id="top-row" container spacing={2}>
          <Grid item xs={12} sm={2}>
            Platform
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/diet" className="footer__link">
              Diet
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/workout" className="footer__link">
              Workout
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/glucose-management" className="footer__link">
              Glucose-Management
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/insights" className="footer__link">
              Insights
            </Link>
          </Grid>
        </Grid>
        <Grid id="second-row" container spacing={2}>
          <Grid item xs={12} sm={2}>
            Ressources
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/success" className="footer__link">
              Success-Stories
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/blog" className="footer__link">
              Blog
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/webinar" className="footer__link">
              Webinar
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/security" className="footer__link">
              security
            </Link>
          </Grid>
        </Grid>
        <Grid id="third-row" container spacing={2}>
          <Grid item xs={12} sm={2}>
            Supports
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/about" className="footer__link">
              about us
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/faq" className="footer__link">
              FAQs
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/careers" className="footer__link">
              Careers
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/press" className="footer__link">
              Press
            </Link>
          </Grid>
        </Grid>
        <Grid id="forth-row" container spacing={2}>
          <Grid item xs={12} sm={2}>
            Contact Us
          </Grid>
          <Grid item xs={12} sm={2} className="footer__link">
            +1 455 555 9852
          </Grid>
          <Grid item xs={12} sm={2} className="footer__link">
            Mountain view, Ca
          </Grid>
          <Grid item xs={12} sm={2}>
            <Link to="/beta" className="footer__link">
              Join The Beta
            </Link>
          </Grid>
          <Grid item xs={12} sm={2} className="footer__link">
            Support@preziba.com
          </Grid>
        </Grid>
      </div>
      <div className="footer__infos">
        Preziba &copy; All Rights Reserved
        <div className="footer__socials">
          <IconButton style={{ color: "darkcyan" }}>
            <TwitterIcon />
          </IconButton>
          <IconButton style={{ color: "darkcyan" }}>
            <LinkedInIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Footer;
