import React from "react";
import "./CustomerGrid.css";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TimerSharpIcon from "@material-ui/icons/TimerSharp";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import HelpIcon from "@material-ui/icons/Help";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function CustomerGrid() {
  return (
    <div className="customerGrid">
      <h1>Get More From Your Customer Support</h1>
      <div className="customerGrid__infos">
        <Grid container alignContent="center" justify="space-between">
          <Grid container justify="center" xs={12} md={4}>
            <AccessTimeIcon />
            <Typography alignContent="center">
              <h3>Onboard Agents Seamlessly</h3>
              <p>
                Get agents up to speed and replying to customers faster and with
                higher accuracy.
              </p>
            </Typography>
          </Grid>
          <Grid container justify="center" xs={12} md={4}>
            <TimerSharpIcon />
            <Typography>
              <h3>Onboard Agents Seamlessly</h3>
              <p>
                Get agents up to speed and replying to customers faster and with
                higher accuracy.
              </p>
            </Typography>
          </Grid>
          <Grid container justify="center" xs={12} md={4}>
            <FavoriteBorderOutlinedIcon />
            <Typography>
              <h3>Onboard Agents Seamlessly</h3>
              <p>
                Get agents up to speed and replying to customers faster and with
                higher accuracy.
              </p>
            </Typography>
          </Grid>
          <Grid container justify="center" xs={12} md={4}>
            <SettingsOutlinedIcon />
            <Typography>
              <h3>Onboard Agents Seamlessly</h3>
              <p>
                Get agents up to speed and replying to customers faster and with
                higher accuracy.
              </p>
            </Typography>
          </Grid>
          <Grid container justify="center" xs={12} md={4}>
            <HelpIcon />
            <Typography>
              <h3>Onboard Agents Seamlessly</h3>
              <p>
                Get agents up to speed and replying to customers faster and with
                higher accuracy.
              </p>
            </Typography>
          </Grid>
          <Grid container justify="center" xs={12} md={4}>
            <TrendingUpIcon />
            <Typography>
              <h3>Onboard Agents Seamlessly</h3>
              <p>
                Get agents up to speed and replying to customers faster and with
                higher accuracy.
              </p>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CustomerGrid;
