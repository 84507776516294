import React from "react";
import "./App.css";
import Cover from "./Cover";
import CustomerGrid from "./CustomerGrid";
import Description from "./Description";
import Footer from "./Footer";
import Logos from "./Logos";
import Testimonial from "./Testimonial";
import VerticalLinearStepper from "./VerticalStepper";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainHeader from "./MainHeader";
import Header from "./Header";
import About from "./About";
import FAQs from "./FAQs";
import ContactUs from "./ContactUs";
import Careers from "./Careers";
import Security from "./Security";
import Press from "./Press";
import Diet from "./Diet";
import Success from "./Success";
import Webinar from "./Webinar";
import Blog from "./Blog";
import Workouts from "./Workouts";
import ComingSoon from "./ComingSoon";
// 4CB3DF
function App() {
  return (
    <Router>
      <div className="app">
        <Switch>
          <Route path="/platform">
            <Header />
            <h1>Platform</h1>
          </Route>
          <Route path="/diet">
            <MainHeader />
            <Diet />
            <Footer />
          </Route>
          <Route path="/workouts">
            <MainHeader />
            <Workouts />
            <Footer />
          </Route>
          <Route path="/ressources">
            <Header />
            <h1>ressources</h1>
          </Route>
          <Route path="/success">
            <Header />
            <Success />
            <Footer />
          </Route>

          <Route path="/webinar">
            <Header />
            <Webinar />
            <Footer />
          </Route>
          <Route path="/blog">
            <Header />
            <Blog />
            <Footer />
          </Route>

          <Route path="/supports">
            <h1>Supports</h1>
          </Route>
          <Route path="/about">
            <Header />
            <About />
            <Footer />
          </Route>
          <Route path="/faq">
            <Header />
            <FAQs />
            <Footer />
          </Route>
          <Route path="/contact-us">
            <Header />
            <ContactUs />
            <Footer />
          </Route>
          <Route path="/careers">
            <Header />
            <Careers />
            <Footer />
          </Route>
          <Route path="/security">
            <MainHeader />
            <Security />
            <Footer />
          </Route>
          <Route path="/press">
            <Header />
            <Press />
            <Footer />
          </Route>
          <Route path="/">
            <ComingSoon />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
