import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import "./Diet.css";

function Diet() {
  return (
    <div className="diet">
      <div className="diet__cover">
        <div className="diet__coverDescription">
          <Grid container>
            <Grid item xs={6} sm={5}>
              <Typography variant="h2" align="left">
                Help customers instantly
                <Typography variant="h6" display="">
                  Agatha reacts and resolves common issues so your agents can
                  focus on delighting customers.
                </Typography>
                <Typography variant="button">
                  <Button
                    size="large"
                    variant="contained"
                    endIcon={<ForwardArrowIcon />}
                    style={{
                      color: "#ff6058",
                    }}
                  >
                    Lets chat
                  </Button>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="diet__section1">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7d1c85b382ba1da677ffe4_graphic-deflections-3.svg"
              alt=""
              style={{ height: 500 }}
            />
          </Grid>
          <Grid item xs={12} md={6} align="left">
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e78cdecdccf08e26eeca5de_icon-deflections.svg"
              alt=""
            />
            <Typography align="left" variant="h5" style={{ color: "orange" }}>
              Agatha Solve
            </Typography>
            <Typography variant="h2" align="left" display="block">
              Auto-respond to common issues quickly
              <Typography variant="h6" align="left" display="block">
                Agatha answers common questions using your knowledge base
                articles or help desk templates, via email or web widget, which
                means customers get answers faster.
              </Typography>
              <Typography variant="h6" align="left">
                Ready to see Aghata in action?
              </Typography>
              <Typography variant="button">
                <Button
                  size="large"
                  variant="contained"
                  endIcon={<ForwardArrowIcon />}
                  style={{
                    backgroundColor: "#ff6058",
                    color: "white",
                  }}
                >
                  Lets chat
                </Button>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="diet__section2">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" align="left" display="block">
              Reduce your customer support team’s workload
              <Typography variant="h6" align="left" display="block">
                Agatha keeps your help desk clean by intercepting common issues
                before they ever reach your agents, which means your team can
                focus on more complex customer issues.
              </Typography>
              <Typography variant="h6" align="left">
                Curious about the technology behind Agatha?
              </Typography>
              <Typography variant="button">
                <Button
                  size="large"
                  variant="contained"
                  endIcon={<ForwardArrowIcon />}
                  style={{
                    backgroundColor: "#ff6058",
                    color: "white",
                  }}
                >
                  Lets chat
                </Button>
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7d1de8f182b59e206afda6_graphic-deflections-4.svg"
              alt=""
            />
          </Grid>
        </Grid>
      </div>
      <div className="diet__section3">
        <Typography variant="h3" align="center">
          Why Diet?
        </Typography>
        <Grid container spacing={10}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="left">
              Fast Deployment
              <Typography variant="subtitle1" align="left">
                Agatha doesn't run on a set of rules, which means she can get up
                and running fast.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="diet__section4">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{ color: "white", paddingLeft: 50 }}
              variant="h4"
              align="left"
            >
              Scale Your Customer Support Operations
              <Typography variant="h6" xs={5} align="left">
                Help your customer support agents focus on delighting customers
              </Typography>
              <Typography variant="button">
                <Button
                  size="medium"
                  variant="contained"
                  endIcon={<ForwardArrowIcon />}
                  style={{
                    color: "#ff6058",
                    backgroundColor: "white",
                    marginBottom: 20,
                  }}
                >
                  Lets chat
                </Button>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Diet;
