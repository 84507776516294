import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Button, CardContent, Icon } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./MainHeader.css";

function MainHeader() {
  const [inHover, setHover] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [show, handleShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 80) {
        handleShow(true);
      } else handleShow(false);
    });
  }, []);

  return (
    <div className={`mainHeader ${show && "mainHeader__appear"}`}>
      <div className="mainHeader__logo">
        <Link to="/">
          <img
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e78c491334fe17447803264_logo-forethought.svg"
            alt=""
            className={` ${
              show && "mainHeader__logoAppear" && "mainHeader__logoDisappear"
            }`}
          />

          <img
            src="https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7cdae7cfa9343a0ba10293_logo-forethought-dark.svg"
            alt=""
            className={`mainHeader__darkLogo ${
              show && "mainHeader__darkLogoAppear"
            }`}
          />
        </Link>
      </div>
      {isMobile ? (
        <>
          {" "}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleMenu}
            aria-label="menu"
            className="burger"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem>
              <Link
                to="/platform"
                className="header__link"
                style={{
                  fontWeight: "bolder",
                  color: "darkcyan",
                }}
              >
                Platform
              </Link>
            </MenuItem>

            <Divider />
            <MenuItem>
              <Link
                to="/diet"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Diet
              </Link>
            </MenuItem>

            <MenuItem>
              <Link
                to="/workouts"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Workouts
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/glucose-management"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Glucose-management
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/insights"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Insights
              </Link>
            </MenuItem>
            <MenuItem></MenuItem>
            <MenuItem>
              <Link
                to="/ressources"
                className="header__link"
                style={{
                  fontWeight: "bolder",
                  color: "darkcyan",
                }}
              >
                Ressources
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <Link
                to="/success"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                success-stories
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/webinar"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                webinar
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/blog"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Blog
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/security"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Security
              </Link>
            </MenuItem>
            <MenuItem></MenuItem>
            <MenuItem>
              <Link
                to="/supports"
                className="header__link"
                style={{
                  fontWeight: "bolder",
                  color: "darkcyan",
                }}
              >
                Supports
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <Link
                to="/about"
                className="header__link "
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                About-us
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/faq"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                FAQs
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/careers"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Careers
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/press"
                className="header__link"
                style={{
                  fontWeight: "normal",
                  color: "black",
                }}
              >
                Press
              </Link>
            </MenuItem>
          </Menu>{" "}
        </>
      ) : (
        <div className="mainHeader__links">
          <Link to="/platform" className="mainHeader__link">
            <Button
              className={`mainHeader__button ${
                show && "mainHeader__buttonAppear"
              }`}
              variant="contained"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              Platform
            </Button>
          </Link>
          {inHover && (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          )}

          <Link to="/ressources" className="mainHeader__link">
            <Button
              className={`mainHeader__button ${
                show && "mainHeader__buttonAppear"
              }`}
              variant="contained"
            >
              Ressources
            </Button>
          </Link>

          <Link to="/supports" className="mainHeader__link">
            <Button
              className={`mainHeader__button ${
                show && "mainHeader__buttonAppear"
              }`}
              variant="contained"
            >
              Supports
            </Button>
          </Link>
          <Link to="/beta" className="header__link">
            <Button endIcon={<ArrowForwardIcon />} variant="contained">
              <Typography variant="button">join the beta</Typography>
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default MainHeader;
