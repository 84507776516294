import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import logo from "./preziba_icon.svg";

import "./ComingSoon.css";
function ComingSoon() {
  return (
    <div className="comingSoon">
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <img src={logo} alt="" />
        </Grid>
      </Grid>
      <div className="comingSoon__cover">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              style={{ color: "white" }}
              align="center"
              id="text"
            >
              Coming Soon
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ComingSoon;
